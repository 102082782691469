import { useState, useEffect } from "react";
import { fetchData } from "../../commonApi";
import DataTable from "react-data-table-component";
import arrow_img from '../../images/date_picker.svg'
import ReactPaginate from 'react-paginate'
import { ChevronDown } from 'react-feather'
import { exportToCsvDonation, exportToPdf } from "../../helpers/helpers";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const GetUsersData = () => {

    const [adminDetails] = useState(() => {
        let details = localStorage.getItem("adminDetails");
        details = JSON.parse(details);
        return details;
    })

    const [users, setUsers] = useState([]);
    const [usersCount, setUsersCount] = useState(0);
    const [noOfRowsPerPage, setNoOfRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [isLoading, setIsLoading] = useState(true);   // ARE USERS LOADING
    const [resetSearch, setResetSearch] = useState(false);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [totalDonation, setTotalDonation] = useState('')
    const [totalOneDonation, setTotalOneDonation] = useState('')
    const [totalMonthlyDonation, setTotalMonthlyDonation] = useState('')
    const [clear,setClear] = useState(false)
    const [sortby , setSortBy] = useState({})

    // CALL THE API WHENEVER THE PAGE, SHOW SELECT, OF SEARCH VALUE CHANGES
    useEffect(() => {
        getUserList();
    }, [page, noOfRowsPerPage, searchValue])

     useEffect(() => {
    if(startDate == "" && endDate == "" && searchValue == ""){
        getUserList()
    }
     },[clear])
    //SETS PAGE TO FIRST PAGE WHEN THE SEARCH VALUE CHANGES AND ITS LENGTH IS GREATED THE 0
    useEffect(() => {
        if (searchValue.length > 0) {
            setResetSearch(true);
            setPage(0);
        } else {
            setResetSearch(false);
        }
    }, [searchValue])

   useEffect(() => {
    if(startDate !== '' && endDate !== ''){
        
        getUserList()
    }
   },[endDate, startDate])
    

    // CHAGES THE CURRENT PAGE
    const handlePagination = page => {
        setPage(page.selected)
    }

    // RESETS THE CURRENT SEARCH VALUE
    const resetSearchValue = () => {
        setSearchValue("");
        setResetSearch(false);
    }

    // CUSTOM PAGINATION
    const CustomPagination = () => (
        <ReactPaginate
            previousLabel={<i className="fa fa-chevron-left" aria-hidden="true"></i>}
            nextLabel={<i className="fa fa-chevron-right" aria-hidden="true"></i>}
            forcePage={page}
            onPageChange={page => handlePagination(page)}
            pageCount={Math.ceil(usersCount / noOfRowsPerPage) || 1}
            breakLabel={'...'}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            activeClassName='active'
            pageClassName='page-item'
            breakClassName='page-item'
            nextLinkClassName='page-link'
            pageLinkClassName='page-link'
            breakLinkClassName='page-link'
            previousLinkClassName='page-link'
            nextClassName='page-item next-item'
            previousClassName='page-item prev-item'
            containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'}
        />
    )


    const handleShowRowsPerPage = (data) => {
        setNoOfRowsPerPage(data);
        let totalPage = Math.ceil(usersCount / data);
        if (page + 1 > totalPage) {
            setPage(totalPage - 1);
        }
    }
const onHandleChange = (e) => {
    // console.log("hello",e)
    if(e.name === "Amount Donated"){
        if(sortby?.sort === "desc"){
            setSortBy({type: "amount", sort:"desc"})
        }

    }
}


// useEffect(() => {
//     getUserList()
// },[sortby])

    const columns = [
        {
            selector: row => [row.donated_by == null || row.display_name ? row.display_name : row.donated_by], name: "Users", sortable: true,
        },
        {
            selector: row => (row.amount), name: "Amount Donated", sortable: true, onclick: {onHandleChange} 
        },
        {
            selector: row => moment(row.created_at).format("MM-DD-YYYY"), name: "Donated Date", sortable: true
        },
        {
            selector: row => [row.donation_type === 2 ? "Monthly Subscription" : "One time"], name: "Donated Type", sortable: true
        },
    ]
  

    const getUserList = async (getAllData) => {
        let obj = {
            data: {
                page: getAllData === "pdf" || getAllData === "csv" ? "all" : (page + 1),
                perpage: noOfRowsPerPage,
                searchvalue: searchValue,
                sorttype: sortby?.type ? sortby?.type : '',
                sortOrder: sortby?.order ?sortby?.order : '',
                startDate: moment(startDate).format('YYYY-MM-DD') === "Invalid date" ? "" : moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD') === "Invalid date" ? "" : moment(endDate).format('YYYY-MM-DD')
            },
            token: adminDetails.token,
            method: "post",
            url: "admin/gettransactions"
        }
        try {
            if (!getAllData) setIsLoading((prevState) => !prevState);

            fetchData(obj)
                .then((res) => {
                    if (getAllData === "pdf") {
                        exportToPdf({
                            array: res.data.cards,
                            startDate: startDate ,
                            endDate: endDate
                        })

                    } else if (getAllData === "csv") {
                        exportToCsvDonation({
                            array: res.data.cards
                        })
                    } else {
                        if (res.data.status === true) {
                            setUsersCount(res.data.filtercount);
                            setUsers(res.data.cards);
                            setTotalDonation(res.data.total_donation)
                            setTotalOneDonation(res.data.check_single_us)
                            setTotalMonthlyDonation(res.data.check_subs_us)
                        } else {
                            setUsers([])
                            setUsersCount(0)
                        }
                    }
                    if (!getAllData) setIsLoading((prevState) => !prevState);
                })
        }
        catch (err) {
            console.log(err);
            setIsLoading(false)
        }
    }


    //CUSTOM DATATABLE STYLES
    const customStyles = {
        headCells: {
            style: {
                backgroundColor: "#2E4C6D",
                color: "#fff",
                fontWeight: "bold"
            },
        },
        rows: {
            style: {
                minHeight: '40px',
            },
        },
    };

    const exportToCsvDonationfn = () => {
        getUserList("csv")
    }

    const exportToPDFfn = () => {
        getUserList("pdf")
    }


    return (
        <div className="w-100">
            <div className="d-flex align-items-center flex-column mb-2 justify-content-between w-100 mt-3 mt-md-0">
                <div className="w-100 d-flex align-items-center justify-content-start">
                    <Link to="/" className='backtoHome mb-0'>
                        <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                        Back to home
                    </Link></div>
                <div className=" w-100 d-flex justify-content-md-start justify-content-center flex-wrap mt-2 mb-3">
                    <div className="show_don mr-4 ">
                        <h5 className="show_don_head">Total Donation</h5>
                        <h5 className="show_don_val">${totalDonation}</h5>
                    </div>
                    <div className="show_don mr-4 ">
                        <h5 className="show_don_head">One Time Donation</h5>
                        <h5 className="show_don_val">{totalOneDonation} Users</h5>
                    </div>
                    <div className="show_don mr-4 ">
                        <h5 className="show_don_head">Monthly Donation</h5>
                        <h5 className="show_don_val">{totalMonthlyDonation} Users</h5>
                    </div>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-end">

                    <div className="align-items-center d-inline-flex">
                        <button className='btn btn-sm text-white mr-3' onClick={exportToPDFfn} style={{
                            background: "#2E4C6D"
                        }}><i className="fa fa-upload text-white pr-2" aria-hidden="true"></i> Export pdf</button>

                        <button className='btn btn-sm text-white' onClick={exportToCsvDonationfn} style={{
                            background: "#2E4C6D"
                        }}><i className="fa fa-upload text-white pr-2" aria-hidden="true"></i> Export csv</button>
                    </div>
                </div>
            </div>

            <div className="col-lg-12 col-12 mt-3 mt-lg-0 boxShadow">
                <div className="adminUsersPageHead">
                    <h6 className="mb-0">Donation History</h6>
                </div>
                <div className="dataTableSelectNsearchCont">
                    <div className="selectNlabelContDatatable">
                        <span className="showLabel">Show</span>
                        <select value={noOfRowsPerPage} onChange={(e) => handleShowRowsPerPage(e.target.value)} className="datatableSelect customFormControl">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                    <div className="filter_dates d-flex align-items-center">
                        <span className="showLabel">Filter:</span>

                        {/* 
                         <div>
                         date_picker.svg
                            </div>      */}
                            <div className="d-flex align-items-center flex-sm-row flex-column ">
                        <div className="d-flex align-items-center date_border">
                            <ReactDatePicker
                                peekNextMonth
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                value={startDate}
                                onChange={(date) =>{
                                    resetSearchValue()
                                    setPage(0)
                                    setStartDate(date)}}
                                selected={startDate}
                                placeholderText="From"
                                className={`don_date don_date1`}
                            />
                            <img src={arrow_img} className="arrow_img arrow_img1" />
                        </div>
                        <div className="d-flex align-items-center date_border mt-sm-0 mt-2">
                            <ReactDatePicker
                                peekNextMonth
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                value={endDate}
                                onChange={(date) => {
                                    setEndDate(date)
                                    setPage(0)
                                    resetSearchValue()
                                }}
                                selected={endDate}
                                placeholderText="To"
                                className={`don_date don_date1`}
                            />
                            <img src={arrow_img} className="arrow_img arrow_img1" />
                        </div>
                        </div>
                    </div>

                    {(endDate || startDate || searchValue) && <div className="">
                        <button className="clear_name " onClick={() => {
                            setEndDate("");
                            setStartDate("")
                            setSearchValue("")
                            setClear(true)
                        }}>
                            clear
                        </button>
                    </div>}
                    <div className="datatableSearch">
                        <label className="showLabel">
                            Search By Name:
                        </label>
                        <div className="crossIconNinputCont">
                            <input type="text" className="customFormControl dataTableSearchInput" value={searchValue} onChange={(e) => {
                                setSearchValue(e.target.value)
                            }} />
                            {/* GETS VISIBLE ON SEARCH LENGTH MORE THAN 0 */}
                            {resetSearch === true && <i className="crossIcon fa fa-times" aria-hidden="true" type="button" onClick={() => { setPage(0); setEndDate(''); setStartDate(''); resetSearchValue() }}></i>}
                        </div>
                    </div>


                </div>
                {isLoading === true ?
                    <>
                        {users?.length === 0 ? <div className="d-flex w-100 align-items-center justify-content-center">
                            <h6 className="endListMessage mt-3 pb-0">No Records to show</h6></div> :
                            <DataTable
                                columns={columns}
                                data={users}
                                highlightOnHover
                                pagination
                                paginationPerPage={noOfRowsPerPage}
                                onSort={onHandleChange}
                                onChangeRowsPerPage={(currentRowsPerPage) => {
                                    setNoOfRowsPerPage(currentRowsPerPage);
                                }}
                                sortIcon={<ChevronDown size={10} />}
                                customStyles={customStyles}
                                paginationDefaultPage={page + 1}
                                paginationComponent={CustomPagination}
                            />}
                    </>
                    : <div className="my-3 text-center">
                        <div className="spinner-border pColor" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>}
            </div>
        </div>
    )

}