import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Add_icon from '../../../images/add-icon.svg'
import Sub_icon from '../../../images/sub_icon1.svg'
import editCommentIcon from '../../../images/pencil_edit.svg'
import { addFaqService, getfaq } from '../../../components/forums/services/MoreServices';


import { deleteResourcesModal, toggleFaqModal } from '../../../redux/actions/moreActions/MoreAc'
import { Loader } from 'react-feather'
import SiteLoader from '../../../user/pageElements/components/SiteLoader'
import DeleteResModal from '../../../components/modals/DeleteResModal'

const fake_data_faq = [
    {
        heading_value: "heading1",
        resourses: []
    },
    {
        heading_value: "heading2",
        resourses: []
    },
    {
        heading_value: "heading3",
        resourses: []
    },
    {
        heading_value: "heading4",
        resourses: []
    }
]


const FaqIndex = () => {

    const [activeCard, setActiveCard] = useState([])
    const [faq_data_real, set_faq_data_real] = useState([...fake_data_faq])
    const dispatch = useDispatch()
    const { faq_data, loading, delete_res_modal } = useSelector(state => state.moreReducer)
    const [adminDetails] = useState(() => {
        let details = localStorage.getItem("adminDetails");
        details = JSON.parse(details);
        return details;
    })

    const handleActiveCard = (data) => {
        if (activeCard.length) {
            let item_pst = false
            const index = activeCard.indexOf(data.id)
            if (index != -1) {
                item_pst = true
            }
            if (!item_pst) {
                setActiveCard([...activeCard, data.id])
            } else {
                let arr = activeCard.filter(item => {
                    return item !== data.id
                })
                setActiveCard([...arr])
            }
        } else {
            setActiveCard([data.id])
        }
    }


    useEffect(() => {
        getfaq(dispatch)
    }, [])

    const handleAddModal = () => {
        dispatch(toggleFaqModal({
            status: true
        }))
    }

    const handlefaqEdit = (data) => {

        if(!data.heading_id || data.heading_id == ""){
            dispatch(toggleFaqModal({
                status: true,
                data: {
                    ...data,
                    heading_value: ''
                }
            }))
        
        }else{
            dispatch(toggleFaqModal({
                status: true,
                data: {
                    ...data,
                    type: "editFaq"
                }
            }))
        }
        
    }

    const handelCountryDelete = (data) => {
        dispatch(deleteResourcesModal({
            status: true,
            data: {
                faq_data: data,
                type: "faqDelete"
            }
        }))
    }

    useEffect(() => {
        if (faq_data.length) {

            let orginal = [...fake_data_faq];
            orginal.forEach((curr, index) => {
                if (index < faq_data.length)
                    orginal.splice(index, 1, faq_data[index])
            })

            // let arr = faq_data_real.map((item, index) => {
            //     let real_data = item
            //     faq_data.map((data, dindex) => {
            //         if (index === dindex) {
            //             real_data = data
            //         }
            //         return { ...real_data }
            //     })
            // })
            set_faq_data_real(orginal)
        }

    }, [faq_data])

    return (<>
        <div>
            <div className="dataTableSelectNsearchCont faq_font px-md-5">
                <div className='d-flex align-items-center justify-content-center w-100 '>
                    <h5 className='faq_title'>Frequently Asked Questions</h5>
                </div>

                {/* <div className='d-flex align-items-center justify-content-end w-100 '>
                    <button onClick={() => { handleAddModal() }} style={{ background: "rgb(46, 76, 109)" }} className='btn btn-sm text-white mr-3'>Add New FAQ</button>
                </div> */}
                {loading ? <SiteLoader /> :
                    faq_data_real.length ? faq_data_real.map((item, index) => {
                        return <div className={`faq_main_div w-100 mb-3`}>
                            <div className='d-flex align-items-center justify-content-center'>
                                <div className={`faq_second_div ${activeCard.indexOf(index) != -1 ? "active_card_class" : ""}`} onClick={(e) => {
                                    if ((e.target).id === "parent") {
                                        handlefaqEdit({
                                            heading_id: item.head_id,
                                            heading_value: item.heading_value,
                                            resourses: item.resourses
                                        })
                                    } else {
                                        handleActiveCard({
                                            id: index,
                                        })
                                    }


                                }} >
                                    <div className={`faq_second_title text-start text-capitalize`}>{`${item.heading_value}`}</div>
                                    <div className='add_icon' >
                                        <img src={editCommentIcon} id="parent" className="pencil_edit_div" onClick={(e) => {


                                        }} />
                                        {activeCard.indexOf(index) != -1 ? <img src={Sub_icon} className="sub_icon" /> : <img src={Add_icon} className="sub_icon" />}
                                    </div>
                                </div>
                                {/* <div className='delete_font_icon_div'>
                                    <i class="fa fa-trash delete_font_icon" aria-hidden="true" onClick={() => {
                                        handelCountryDelete({ faq_id: item.id })
                                    }}></i>
                                </div> */}
                            </div>
                            <div className={`faq_second_textarea ${activeCard.indexOf(index) != -1 ? "d-block active_answer" : "d-none h-0"}`}>
                                {item?.resourses?.map((data, dIndex) => {
                                    return <div className={`faq_second_textarea2`}>

                                        <div>
                                            <div className="res_anchor ">
                                                {`Q${dIndex + 1}. ${data.question_value} ?`}</div>

                                            <div className="res_description mt-1">
                                                A. {data.answer_value}
                                            </div>


                                        </div>


                                    </div>
                                })}

                            </div>

                        </div>
                    }) : ''}


            </div>
        </div>
        {delete_res_modal.status && <DeleteResModal />}

    </>)
}

export default FaqIndex