// Actions
const moreActions = {
    "FAQ_MODAL": "FAQ_MODAL",
    "ADD_FAQ_DATA": "ADD_FAQ_DATA",
    "EDIT_FAQ_DATA": "EDIT_FAQ_DATA",
    "GET_FAQ_DATA": "GET_FAQ_DATA",
    "SET_LOADING_DATA": "SET_LOADING_DATA",
    "RESOURSES_MODAL": "RESOURSES_MODAL",
    "ADD_RESOURSES_DATA": "ADD_RESOURSES_DATA",
    "EDIT_RESOURSES_DATA": "EDIT_RESOURSES_DATA",
    "GET_RESOURSES_DATA": "GET_RESOURSES_DATA",
    "DELETE_RESOURSES_MODAL": "DELETE_RESOURSES_MODAL"
}

// Actions creators
const toggleFaqModal = payload => {
    return {
        type: moreActions.FAQ_MODAL,
        payload
    }
}

const addFaq = payload => {
    return {
        type: moreActions.ADD_FAQ_DATA,
        payload
    }
}

const editFaq = payload => {
    return {
        type: moreActions.EDIT_FAQ_DATA,
        payload
    }
}

const getFaqAdded = payload => {
    return {
        type: moreActions.GET_FAQ_DATA,
        payload
    }
}

const setloading = payload => {
    return {
        type: moreActions.SET_LOADING_DATA,
        payload
    }
}

//resources

const toggleResourcesModal = payload => {
    return {
        type: moreActions.RESOURSES_MODAL,
        payload
    }
}

const addResourcesData = payload => {
    return {
        type: moreActions.ADD_RESOURSES_DATA,
        payload
    }
}

const editResourcesData = payload => {
    return {
        type: moreActions.EDIT_RESOURSES_DATA,
        payload
    }
}

const getResourcesData = payload => {
    return {
        type: moreActions.GET_RESOURSES_DATA,
        payload
    }
}

const deleteResourcesModal = payload => {
    return {
        type: moreActions.DELETE_RESOURSES_MODAL,
        payload
    }
}
// Exports
export {
    toggleFaqModal,
    addFaq,
    editFaq,
    getFaqAdded,
    setloading,
    toggleResourcesModal,
    addResourcesData,
    editResourcesData,
    getResourcesData,
    deleteResourcesModal,
    moreActions
}
