import React from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import auth from '../../../behindScenes/Auth/AuthCheck';
import { Link } from 'react-router-dom';
import ResourcesIndex from '../../../components/more/ResourcesIndex';


export const Resources = () => {

    if (!auth()) {
        window.location.href = "/talkplacepanel"
    }

    return (
        <div className="container-fluid">
            {auth() &&
                <div className="row">
                    <Header links={true} fullWidth={true} />
                    <div className="preventHeader">preventHead</div>
                    <div className="container container_22 py-md-4 px-md-5 p-md-3 preventFooter">
                        <div className="row forPosSticky">
                            <Link to="/" className='backtoHome'>
                                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                                Back to home
                            </Link>
                            <section className="col-lg-12 col-12 mt-3 mt-lg-0 ">
                                
                               <ResourcesIndex />
                            </section>
                        </div>
                    </div>
                    <Footer />
                </div>}
        </div>
    )
}
