import Button from '@restart/ui/esm/Button';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { apiStatus } from '../../helpers/status';
import { deleteForumAcFn } from '../../redux/actions/forumsAc/forumsAc';
import { deleteResourcesModal } from '../../redux/actions/moreActions/MoreAc';
import { deleteForumService } from '../forums/services/forumServices';
import { deleteCountry, deletefaq } from '../forums/services/MoreServices';

export default function DeleteResModal () {

    // Hooks and vars
    const dispatch = useDispatch()
    const {delete_res_modal:{status, data} ,isLoading} = useSelector(state => state.moreReducer)

    // Functions

    // Closes the modal
    const closeModal = () => {
        dispatch(deleteResourcesModal({
            status: false,
            data: {}
        }))
    }

    // Deletes the forum
    const deleteForum = () => {
        if(data.type === "countryDelete"){
            deleteCountry(data.country_data , dispatch)
        }
        if(data.type === "faqDelete"){
            data.handelCountryDelete(data.faq_data , dispatch)
        }else {
            data.handleRemoveLink_2({...data.item_data})
            dispatch(deleteResourcesModal({
                status: false,
                data: {}
            }))
        }
        
        
    }

    return (
        <>
            <Modal show={status} onHide={closeModal}>
                <Modal.Header>
                    <h6>{(data.type === "faqDelete") ? "Delete FAQ" : "Delete Resources" }</h6>
                </Modal.Header>
                <Modal.Body className="privacyBody">

                    { data.type === "countryDelete" ? 
                    "Do you really want to delete this Country? Deleting the country will remove all the resources entered for it."
                : data.type === "faqDelete" ? 
                "Do you really want to delete this FAQ ?"
                 : "Do you really want to delete this Link ?"}
                </Modal.Body>
                <Modal.Footer className="pt-0 justify-content-center">
                    <Button className="modalFootBtns btn" variant="primary" onClick={closeModal}>
                        No
                    </Button>
                    <Button className="modalFootBtns btn" variant="primary" onClick={deleteForum}>
                        {isLoading ?
                            <div className="spinner-border text-white" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : "Yes"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
