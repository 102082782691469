import { async } from "@firebase/util"
import { useDispatch } from "react-redux"
import { fetchData } from "../../../commonApi"
import { areAtLastPage, isAdminLoggedIn, resHandler } from "../../../helpers/helpers"
import { getKeyProfileLoc } from "../../../helpers/profileHelper"
import toastMethods from "../../../helpers/components/Toaster"
import { apiStatus } from "../../../helpers/status"
import { addFaq, addResourcesData, deleteResourcesModal, editFaq, editResourcesData, getFaqAdded, getResourcesData, setloading, toggleFaqModal, toggleResourcesModal } from "../../../redux/actions/moreActions/MoreAc"
import { searchAcFn } from "../../../redux/actions/searchAc/searchAc"
import auth from "../../../user/behindScenes/Auth/AuthCheck"
import SetAuth from "../../../user/behindScenes/SetAuth"
import { showSubCommentsFn, subComIniVal } from "../detailPage/comments/ForumCommProvider"
const adminDetails = () => {
    let details = localStorage.getItem("adminDetails");
    details = JSON.parse(details);
    return details;
}


const addFaqService = async (data, dispatch) => {

    try {
        dispatch(setloading(true))
        let obj = {
            token: adminDetails()?.token ?? "",
            method: "post",
            url: `admin/addfaq`,
            data: {
                ...data
            }
        }

        let res = await fetchData(obj)

        if (res?.data?.status) {

       getfaq(dispatch)     
        // if (data?.id) {
        //     dispatch(editFaq(
        //         [{
        //             id: data?.id,
        //             question_value: data.question_value,
        //             answer_value: data.answer_value
        //         }]
        //     ))
        // } else {
        //     dispatch(addFaq([{
        //         id: res?.data?.faq_id,
        //         question_value: data.question_value,
        //         answer_value: data.answer_value
        //     }]))
        // }

        dispatch(toggleFaqModal({status: false}))

        dispatch(setloading(false))
       
    }else {
        dispatch(setloading(false))

        console.log(res?.data?.message)
    }
    } catch (error) {
        console.log({ error })
    }

}


const getfaq = async (dispatch) => {

    try {
        dispatch(setloading(true))

        let obj = {
            token: adminDetails()?.token ?? "",
            method: "post",
            url: `admin/getfaq`,
            data: {}
        }

        let res = await fetchData(obj)

        if (res?.data?.status) {

            dispatch(getFaqAdded(res?.data?.faq))
            dispatch(setloading(false))
            
        }else {
            dispatch(setloading(false))

        }

    } catch (error) {
        console.log({ error })
    }
}

const addResourcesService = async (data, dispatch) => {

    try {
        dispatch(setloading(true))
        let obj = {
            token: adminDetails()?.token ?? "",
            method: "post",
            url: `admin/addResources`,
            data: {
                res_id: data?.id && data?.id != '' ? data?.id : "",
                res_country: data?.country,
                resources: data?.resources
            }
        }

        let res = await fetchData(obj)

        if (res?.data?.status) {
       
            getresources(dispatch)
        

        dispatch(toggleResourcesModal({status: false}))

        dispatch(setloading(false))
       
    }else {
        dispatch(setloading(false))
        toastMethods.toaster2Info(`${res?.data?.message}`)
        console.log(res?.data?.message)
    }
    } catch (error) {
        console.log({ error })
    }

}
const getresources = async (dispatch) => {

    try {
        dispatch(setloading(true))

        let obj = {
            token: adminDetails()?.token ?? "",
            method: "post",
            url: `admin/getResources`,
            data: {}
        }

        let res = await fetchData(obj)

        if (res?.data?.status) {

            dispatch(getResourcesData(res?.data?.check_update))
            dispatch(setloading(false))
            
        } else {
            dispatch(setloading(false))

        }

    } catch (error) {
        console.log({ error })
    }
}

const deleteresources = async (obj_data,dispatch) => {

    try {
        dispatch(setloading(true))

        let obj = {
            token: adminDetails()?.token ?? "",
            method: "post",
            url: `admin/deleteRes`,
            data: {...obj_data}
        }

        let res = await fetchData(obj)

        if (res?.data?.status) {

            getresources(dispatch)
            dispatch(setloading(false))
            
        } else {
            dispatch(setloading(false))

        }

    } catch (error) {
        console.log({ error })
    }
}

const deleteCountry = async (obj_data,dispatch) => {

    try {
        dispatch(setloading(true))

        let obj = {
            token: adminDetails()?.token ?? "",
            method: "post",
            url: `admin/deleteMainRes`,
            data: {...obj_data}
        }

        let res = await fetchData(obj)

        if (res?.data?.status) {

            getresources(dispatch)
            dispatch(setloading(false))
            dispatch(deleteResourcesModal({status: false, data: {}}))
            toastMethods.toaster2Info(`${res?.data?.message}`)
            
        } else {
            dispatch(setloading(false))

        }

    } catch (error) {
        console.log({ error })
    }
}

const deletefaq = async (obj_data,dispatch) => {

    try {
        dispatch(setloading(true))

        let obj = {
            token: adminDetails()?.token ?? "",
            method: "post",
            url: `admin/deletefaq`,
            data: {...obj_data}
        }

        let res = await fetchData(obj)

        if (res?.data?.status) {

            getfaq(dispatch)
            dispatch(setloading(false))
            dispatch(deleteResourcesModal({status: false, data: {}}))
            toastMethods.toaster2Info(`${res?.data?.message}`)
            
        } else {
            dispatch(setloading(false))

        }

    } catch (error) {
        console.log({ error })
    }
}

export {
    addFaqService,
    getfaq,
    addResourcesService,
    getresources,
    deleteresources,
    deleteCountry,
    deletefaq
}