import { apiStatus } from "../../../helpers/status";
import { moreActions } from "../../actions/moreActions/MoreAc";

const initialState = {
    faq_modal: {
        status: false,
        data: {}
    },
    faq_data: [],
    resources_data: [],
    resources_modal: {
        status: false,
        data: {}
    },
    delete_res_modal:{
        status: false,
        data: {}
    }, 
    loading: false
}


const moreReducer = (state = initialState, action) => {
    switch (action.type) {
        case moreActions.FAQ_MODAL: {
            return ({ ...state, faq_modal: { status: action?.payload?.status, data: action?.payload?.data } })
        }
        case moreActions.ADD_FAQ_DATA: {
            return ({ ...state, faq_data: [...state?.faq_data, ...action?.payload] })
        }
        case moreActions.GET_FAQ_DATA: {
            return ({ ...state, faq_data: [...action?.payload] })
        }
        case moreActions.EDIT_FAQ_DATA: {
            return ({
                ...state, faq_data: [...state?.faq_data].map(item => {
                   if(item.id === action?.payload[0].id){
                    let payload_data = action?.payload[0]
                    return {
                        ...payload_data
                    }
                   }else {
                    return item
                   }
                })
            })
        }
        case moreActions.SET_LOADING_DATA: {
            return ({ ...state, loading: action?.payload })
        }
        case moreActions.RESOURSES_MODAL: {
            return ({ ...state, resources_modal: { status: action?.payload?.status, data: action?.payload?.data } })
        }
        case moreActions.ADD_RESOURSES_DATA: {
            return ({ ...state, resources_data: [...state?.resources_data, ...action?.payload] })
        }
        
        case moreActions.GET_RESOURSES_DATA: {
            return ({ ...state, resources_data: [...action?.payload] })
        }
        case moreActions.EDIT_RESOURSES_DATA: {
            return ({
                ...state, resources_data: [...state?.resources_data].map(item => {
                   if(item.id === action?.payload[0].id){
                    let payload_data = action?.payload[0]
                    return {
                        ...payload_data
                    }
                   }else {
                    return item
                   }
                })
            })
        }
        case moreActions.DELETE_RESOURSES_MODAL: {
            return ({ ...state, delete_res_modal: {status: action?.payload?.status, data: action?.payload?.data } })
        }
        
        default: return state;
    }
}


export default moreReducer;