import Button from '@restart/ui/esm/Button';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { apiStatus } from '../../helpers/status';
import { deleteResourcesModal, toggleFaqModal } from '../../redux/actions/moreActions/MoreAc';
import { addFaqService, deletefaq } from '../forums/services/MoreServices';
import * as yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { uid } from '../../helpers/helpers';
import toastMethods from '../../helpers/components/Toaster';
export default function AddFAQModal() {

    // Hooks and vars
    const dispatch = useDispatch()
    const { faq_modal: { status, data }, delete_res_modal } = useSelector(state => state.moreReducer)
    const [manualError, setManualError] = useState(false)
    const isLoading = false
    const createForumSchema = yup.object().shape({
        heading: yup.string().required("This field is required !"),
        faq: yup.array().of(yup.object().shape({
            question_value: yup.string().required("This field is required !"),
            answer_value: yup.string().required("This field is required !")
        })
        ),


    });
    const { register, formState: { errors }, handleSubmit, control, reset, clearErrors, getValues, setValue } = useForm({
        mode: "onChange",
        resolver: yupResolver(createForumSchema)
    })


    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "faq", // unique name for your Field Array
    });


    useEffect(() => {
        if (Object.keys(data).length != 0) {
            if (data?.resourses?.length) {
                reset({
                    faq: [
                        ...data.resourses
                    ]
                })
            } else {
                append(
                    {
                        question_value: "",
                        answer_value: ""
                    }
                )
            }
            setValue('heading', data.heading_value)
        } else {
            append(
                {
                    question_value: "",
                    answer_value: ""
                }
            )
        }
    }, [])
    // Functions
    // Closes the modal
    const closeModal = () => {
        dispatch(toggleFaqModal({
            status: false,
            data: {}
        }))
    }


    const handelCountryDelete = (data) => {
        remove(data?.index)
        if(data.faq_id && data.faq_id != ''){
            deletefaq(data, dispatch)
        }else{
            dispatch(deleteResourcesModal({
                status: false,
                data: {}
            }))
        }

    }

    const handleRemoveLink = (data) => {
        return dispatch(deleteResourcesModal({
            status: true,
            data: {
                faq_data: data,
                type: "faqDelete",
                handelCountryDelete
            }
        }))

    }


    // Deletes the forum
    const deleteForum = async (form_data) => {

        if (!form_data.faq.length) {
            return toastMethods.toaster2Info("You need to add atleast one FAQ")
        }

        let obj_data;
        obj_data = {
            faq_heading: form_data.heading,
            resourses: [...form_data.faq]
        }

        if (data?.type === "editFaq") {
            obj_data = {
                ...obj_data,
                heading_id: data.heading_id,
            }
        }

        const response = await addFaqService(obj_data, dispatch)


    }

    const handelMoreLink = () => {
        append(
            {
                question_value: "",
                answer_value: ""
            }
        )
    }

    return (
        <>
            <Modal show={status} size="lg" onHide={closeModal} className={`${delete_res_modal.status ? "d-none" : ""}`} >
                <Modal.Header>
                    <h6>{data?.type === "editFaq" ? "Edit FAQ" : "Add FAQ"}</h6>
                    <span onClick={closeModal} type="button">
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </span>
                </Modal.Header>
                <Modal.Body className="privacyBody privacyBody_5">
                    <div className='w-100 px-5'>
                        <input
                            placeholder='heading...'
                            className={`add_faq_input input_fq
                            ${errors?.heading?.message ? "form-control is-invalid mb-0 add_faq_input_eror" : ''}
                            `}
                            {...register("heading")}
                        />
                        {errors?.heading?.message ? <div className="invalid-feedback is-invalid d-flex align-items-center justifl-content-start ">
                            <strong>{errors?.heading?.message}</strong></div> : ''}


                        {/*here question array started*/}

                        {fields?.length ? fields?.map((item, index) => {

                            return <div className='w-100'>

                                <div className='mt-2 w-100 d-flex align-items-center justify-content-end'>
                                    <div className='remove_text_style' onClick={() => {
                                        handleRemoveLink({ index, faq_id: item.faq_id })
                                    }}>
                                        Remove FAQ
                                    </div>
                                </div>

                                <input
                                    key={index + uid()}
                                    placeholder='Write Question...'
                                    {...register(`faq.${index}.question_value`)}
                                    className={`add_faq_input input_fq
                            ${(errors?.faq && errors?.faq[index]?.question_value?.message) ? "form-control is-invalid mb-0 add_faq_input_eror" : ''}
                            `}
                                    onChange={(e) => {

                                    }}

                                />
                                {(errors?.faq && errors?.faq[index]?.question_value?.message) ? <div className="invalid-feedback is-invalid d-flex align-items-center justifl-content-start ">
                                    <strong>{errors?.faq && errors?.faq[index].question_value?.message}</strong>
                                </div> : ''}


                                <textarea
                                    key={index + uid()}
                                    placeholder='Write Answer...'
                                    {...register(`faq.${index}.answer_value`)}
                                    className={`add_faq_input text_fq
                            ${(errors?.faq && errors?.faq[index]?.answer_value?.message) ? "form-control is-invalid mb-0 add_faq_input_eror" : ''}
                            `}
                                    onChange={(e) => {

                                    }}
                                />

                                {(errors?.faq && errors?.faq[index]?.answer_value?.message)
                                    ? <div className="invalid-feedback is-invalid d-flex align-items-center justifl-content-start ">
                                        <strong>
                                            {(errors?.faq && errors?.faq[index]?.answer_value?.message)}
                                        </strong>
                                    </div> : ''}

                                {(index < fields.length - 1) ? <hr className='res_hr' /> : ''}
                            </div>
                        }) : ""}

                        <div className='d-flex align-items-center justify-content-end add_link'>
                            <div
                                onClick={() => {
                                    handelMoreLink()
                                }}>Add more FAQ</div>
                        </div>
                        {/*here question array end*/}
                    </div>

                </Modal.Body>
                <Modal.Footer className="pt-0 justify-content-center">
                    <Button className="modalFootBtns btn" variant="primary" onClick={handleSubmit(deleteForum)}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
