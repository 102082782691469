import React from 'react';
import { Link, NavLink } from "react-router-dom";
import { useLocation } from 'react-router';
import forumsAdminIcon from '../../../images/forumsAdminIconMob.svg'
import forumsAdminIconActive from '../../../images/forumsAdminIconMobActive.svg';
import Donation_his from '../../../images/donation_his.svg'
import Donation_his2 from '../../../images/donation_his2.svg'
import More_icon from '../../../images/mob_more_icon.svg'
import More_icon2 from '../../../images/web_more_icon.svg' 

export default function Footer() {

    let useLocations = useLocation();
    let currentUrl = (useLocations.pathname).replace("/", "");
    const isReportedItemActive = currentUrl === "admin/reportedposts" || currentUrl === "admin/reported" || currentUrl === "admin/reportedComments"
    const isMoreactive = currentUrl === "admin/resources" || currentUrl === "admin/faq"

    return (
        <footer className="col-12 d-block d-md-none footer admin">
            <div className="linksCont container-fluid pt-2">
                <div className="linkBtns all_users">
                    <i className={`fa fa-user moveABit adminHeaderIcons ${currentUrl === "admin/users" ? "oColor" : "text-white"}`} aria-hidden="true"></i>
                    <span
                        className={`footLinkName ${currentUrl === "admin/users" ? "activeLinkOfHeader" : ""}`}>Users
                    </span>
                    <div className="reportItems report2">
                        <div className="reportItem">
                            <NavLink to="/admin/users" className="headerNavLinks">
                                <i className={`fa fa-user moveABit adminHeaderIcons ${currentUrl === "admin/users" ? "oColor" : "text-white"}`} aria-hidden="true"></i>
                                <span
                                    className={`footLinkName ${currentUrl === "admin/users" ? "activeLinkOfHeader" : ""}`}>Users
                                </span>
                            </NavLink>
                            <NavLink to="/admin/userdonation" className="headerNavLinks">
                                <img src={Donation_his} className={`mr-2 ${currentUrl !== "admin/userdonation" ? "" : "don_img2"}`} />
                                <img src={Donation_his2} className={`mr-2 ${currentUrl === "admin/userdonation" ? "" : "don_img2"}`} />

                                <span
                                    className={`headLinkName ${currentUrl === "admin/userdonation" ? "activeLinkOfHeader" : ""}`}>Donation History</span>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="linkBtns dropdownLink">
                    <div className="linkBtnsAnchor headerLinks">
                        <span className="footerIconCont">
                            <img src={!currentUrl.includes("forum") ? forumsAdminIcon : forumsAdminIconActive} alt="" />
                        </span>
                        <span
                            className={`headLinkName footLinkName ${(currentUrl.includes("forum")) ? "activeLinkOfHeader" : ""}`}>Forums</span>
                        <div className="reportItems">
                            <div className="reportItem">
                                <NavLink to="/admin/forums" className="headerNavLinks">
                                    <i className={`fa fa-circle-o-notch moveABit adminHeaderIcons  ${currentUrl === "/admin/forums" ? "oColor" : ""}`} aria-hidden="true"></i>
                                    <span
                                        className={`headLinkName ${currentUrl === "/admin/forums" ? "activeLinkOfHeader" : ""}`}>All Forums</span>
                                </NavLink>
                            </div>
                            <div className="reportItem">
                                <NavLink to="/admin/reported_forums" className="headerNavLinks">
                                    <i className={`fa fa-exclamation-triangle moveABit adminHeaderIcons  ${currentUrl === "/forums/reported_forums" ? "oColor" : ""}`} aria-hidden="true"></i>
                                    <span
                                        className={`headLinkName ${currentUrl === "/forums/reported_forums" ? "activeLinkOfHeader" : ""}`}>Reported Forums</span>
                                </NavLink>
                            </div>
                            <div className="reportItem">
                                <NavLink to="/admin/reported_forum_comments" className="headerNavLinks">
                                    <i className={`fa fa-file moveABit adminHeaderIcons  ${currentUrl === "admin/reported_forum_comments" ? "oColor" : ""}`} aria-hidden="true"></i>
                                    <span className={`headLinkName ${currentUrl === "admin/reported_forum_comments" ? "activeLinkOfHeader" :
                                        ""}`}>Reported comments</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="linkBtns dropdownLink">
                    <div className="linkBtnsAnchor headerLinks">
                        <i className={`fa fa-exclamation-triangle moveABit adminHeaderIcons ${isReportedItemActive ? "oColor" : "text-white"}`} aria-hidden="true"></i>
                        <span
                            className={`footLinkName ${isReportedItemActive ? "activeLinkOfHeader" : ""}`}>Reports
                        </span>

                        <div className="reportItems">
                            <div className="reportItem">
                                <NavLink to="/admin/reportedposts" className="headerNavLinks">
                                    <i className={`fa fa-exclamation-triangle moveABit adminHeaderIcons  ${currentUrl === "admin/reportedposts" ? "oColor" : ""}`} aria-hidden="true"></i>
                                    <span
                                        className={`headLinkName ${currentUrl === "admin/reportedposts" ? "activeLinkOfHeader" : ""}`}>Reported posts</span>
                                </NavLink>
                            </div>
                            <div className="reportItem">
                                <NavLink to="/admin/reported" className="headerNavLinks">
                                    <i className={`fa fa-flag-o moveABit adminHeaderIcons  ${currentUrl === "admin/reported" ? "oColor" : ""}`} aria-hidden="true"></i>
                                    <span className={`headLinkName ${currentUrl === "admin/reported" ? "activeLinkOfHeader" :
                                        ""}`}>Reported Users</span>
                                </NavLink>
                            </div>
                            <div className="reportItem">
                                <NavLink to="/admin/reportedComments" className="headerNavLinks">
                                    <i className={`fa fa-file moveABit adminHeaderIcons  ${currentUrl === "admin/reportedComments" ? "oColor" : ""}`} aria-hidden="true"></i>
                                    <span className={`headLinkName ${currentUrl === "admin/reportedComments" ? "activeLinkOfHeader" :
                                        ""}`}>Reported Comments</span>
                                </NavLink>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="linkBtns">
                    <Link to="/admin/complaints" className="linkBtnsAnchor">
                        <i className={`fa fa-align-justify adminHeaderIconsLastTwo  ${currentUrl === "admin/complaints" ? "oColor" : "text-white"}`} aria-hidden="true"></i>
                    </Link>
                    <span
                        className={`footLinkName ${currentUrl === "admin/complaints" ? "activeLinkOfHeader" : ""}`}>Complaints
                    </span>
                </div>

                <div className="linkBtns dropdownLink">
                    <div className="linkBtnsAnchor headerLinks">
                        <img src={More_icon} className={`more_icon_col2 ${currentUrl !== "admin/faq" && currentUrl !== "admin/resources" ? "" : " moveABit adminHeaderIcons don_img2  "}`} />
                        <img src={More_icon2} className={`more_icon_col2 ${currentUrl === "admin/faq" || currentUrl == "admin/resources" ? "" : " moveABit adminHeaderIcons don_img2 "}`} />

                        {/* <i className={`fa fa-exclamation-triangle moveABit adminHeaderIcons ${isReportedItemActive ? "oColor" : "text-white"}`} aria-hidden="true"></i> */}
                        <span
                            className={`footLinkName ${isMoreactive ? "activeLinkOfHeader" : ""}`}>More
                        </span>

                        <div className="reportItems reportItems_22">
                            <div className="reportItem">
                                <NavLink to="/admin/resources" className="headerNavLinks">
                                    <i className={`fa fa-user moveABit adminHeaderIcons mr-2  ${currentUrl === "admin/resources" ? "oColor" : ""}`} aria-hidden="true"></i>

                                    <span
                                        className={`headLinkName ${currentUrl === "admin/resources" ? "activeLinkOfHeader" : ""}`}>Resources</span>
                                </NavLink>
                            </div>
                            <div className="reportItem">
                                <NavLink to="/admin/faq" className="headerNavLinks">
                                    <img src={Donation_his} className={`mr-2 ${currentUrl !== "admin/faq" ? "" : "don_img2"}`} />
                                    <img src={Donation_his2} className={`mr-2 ${currentUrl === "admin/faq" ? "" : "don_img2"}`} />
                                    <span className={`headLinkName ${currentUrl === "admin/faq" ? "activeLinkOfHeader" :
                                        ""}`}>faq</span>
                                </NavLink>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </footer>
    )
}
