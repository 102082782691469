import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Add_icon from '../../../images/add-icon.svg'
import Sub_icon from '../../../images/sub_icon1.svg'
import editCommentIcon from '../../../images/pencil_edit.svg'
import { addFaqService, getfaq, getresources } from '../../../components/forums/services/MoreServices';


import { deleteResourcesModal, toggleResourcesModal } from '../../../redux/actions/moreActions/MoreAc'
import { Loader } from 'react-feather'
import SiteLoader from '../../../user/pageElements/components/SiteLoader'
import AddResourcesModal from '../../../components/modals/AddResourcesModal'
import DeleteResModal from '../../../components/modals/DeleteResModal'




const ResourcesIndex = () => {

    const [activeCard, setActiveCard] = useState([])
    const dispatch = useDispatch()
    const { resources_data, resources_modal, loading , delete_res_modal} = useSelector(state => state.moreReducer)
    const [adminDetails] = useState(() => {
        let details = localStorage.getItem("adminDetails");
        details = JSON.parse(details);
        return details;
    })
    const handleActiveCard = (data) => {
        if (activeCard.length) {
            let item_pst = false
            const index = activeCard.indexOf(data.id)
            if (index != -1) {
                item_pst = true
            }
            if (!item_pst) {
                setActiveCard([...activeCard, data.id])
            } else {
                let arr = activeCard.filter(item => {
                    return item !== data.id
                })
                setActiveCard([...arr])
            }
        } else {
            setActiveCard([data.id])
        }
    }

    const handelCountryDelete = (data) => {
      dispatch(deleteResourcesModal({
        status: true,
        data:{
            country_data: data,
            type: "countryDelete"
        }
      }))
    }

    useEffect(() => {
        getresources(dispatch)
    }, [])
    const handleAddModal = () => {
        dispatch(toggleResourcesModal({
            status: true
        }))
    }

    const handlefaqEdit = (data) => {
        dispatch(toggleResourcesModal({
            status: true,
            data: {
                ...data,
                type: "editRes"
            }
        }))
    }

    return (<>
        <div>
            <div className="dataTableSelectNsearchCont faq_font px-md-5">
                <div className='d-flex align-items-center justify-content-center w-100 '>
                    <h5 className='faq_title'>Contact Support Sites</h5>
                </div>

                <div className='d-flex align-items-center justify-content-end w-100 '>
                    <button onClick={() => { handleAddModal() }} style={{ background: "rgb(46, 76, 109)" }} className='btn btn-sm text-white mr-3'>Add Resources</button>
                </div>
                {loading ? <SiteLoader /> :
                    resources_data.length ? resources_data.map((item, index) => {
                        return <div className={`faq_main_div w-100 mb-3`}>
                            <div className='d-flex align-items-center justify-content-center'>
                                <div className={`faq_second_div ${activeCard.indexOf(item.id) != -1 ? "active_card_class" : ""}`} onClick={(e) => {

                                    if ((e.target).id === "parent") {
                                        handlefaqEdit({
                                            ...item
                                        })
                                    } else {
                                        handleActiveCard({
                                            id: item.id,
                                        })
                                    }
                                
                                   
                                }} >
                                    <div className={`faq_second_title text-start`}>{`${item.country}`}</div>
                                    <div className='add_icon' >

                                        <img src={editCommentIcon} id="parent" className="pencil_edit_div" onClick={(e) => {
                                           
                                            
                                        }} />
                                        {activeCard.indexOf(item.id) != -1 ? <img src={Sub_icon}  className="" /> : <img src={Add_icon} className="sub_icon"  />}
                                    </div>
                                </div>

                                <div className='delete_font_icon_div'>
                                    <i class="fa fa-trash delete_font_icon" aria-hidden="true" onClick={() => {
                                        handelCountryDelete({country_id: item.id})
                                    }}></i>
                                </div>

                            </div>
                            <div className={`faq_second_textarea p-0 ${activeCard.indexOf(item.id) != -1 ? "d-block active_answer" : "d-none h-0"}`}>
                                {item?.resources.map((data, dIndex) => {
                                    return <div className={`faq_second_textarea2 `}>


                                        <div>
                                            <a target="_blank" href={data.link} className="res_anchor text-break">
                                                {data.link}</a>

                                            <div className="res_description mt-1">
                                                {data.description}
                                            </div>


                                        </div>


                                    </div>
                                })}

                            </div>

                        </div>
                    }) : ''}


            </div>
        </div>
        {resources_modal.status && <AddResourcesModal />}
        {delete_res_modal.status && <DeleteResModal />}

    </>)
}

export default ResourcesIndex