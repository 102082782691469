import React from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import FaqIndex  from '../../../components/more/FaqIndex';
import auth from '../../../behindScenes/Auth/AuthCheck';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddFAQModal from '../../../../components/modals/AddFAQModal';


export const Faq = () => {
    const { faq_modal: { status, data } } = useSelector(state => state.moreReducer)

    if (!auth()) {
        window.location.href = "/talkplacepanel"
    }

    return (
        <div className="container-fluid">
            {auth() &&
                <div className="row">
                    <Header links={true} fullWidth={true} />
                    <div className="preventHeader">preventHead</div>
                    <div className="container container_22 py-md-4 px-md-5 p-md-3 preventFooter">
                        <div className="row forPosSticky b-0">
                            <Link to="/" className='backtoHome'>
                                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                                Back to home
                            </Link>
                            <section className="col-lg-12 col-12 mt-3 mt-lg-0 ">
                                {/* <div className="adminUsersPageHead">
                                    <h6 className="mb-0">Frequently Asked Questions</h6>
                                </div> */}
                                <FaqIndex />
                                {status && <AddFAQModal />}
                            </section>
                        </div>
                    </div>
                    <Footer />
                </div>}
        </div>
    )
}
