import Button from '@restart/ui/esm/Button';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { apiStatus } from '../../helpers/status';
import { deleteResourcesModal, toggleResourcesModal } from '../../redux/actions/moreActions/MoreAc';
import { addResourcesService, deleteresources } from '../forums/services/MoreServices';
import Select from 'react-select'
import { countries } from '../../helpers/CountriesName';
import { uid } from '../../helpers/helpers';
import * as yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import rejectRequest from '../../images/cross_cards.svg'
import Feedback from 'react-bootstrap/esm/Feedback';
import DeleteResModal from './DeleteResModal';


export default function AddResourcesModal() {

    // Hooks and vars
    const dispatch = useDispatch()

    const { resources_modal: { status, data }, delete_res_modal } = useSelector(state => state.moreReducer)
    const [resources, setResources] = useState([])
    const [country, setCountry] = useState('')
    const [countryError, setCountryError] = useState(false)

    const isLoading = false
    const input_ref = useRef()
    const text_ref = useRef()
    const createForumSchema = yup.object().shape({
        test: yup.array().of(yup.object().shape({
            link: yup.string().url("Link must be a valid URL for example (https://www.example.com) ").required("This field is required !"),
            description: yup.string().required("This field is required !")
        })
        ),
    });
    const { register, formState: { errors }, handleSubmit, control, reset, clearErrors, getValues, setValue } = useForm({
        mode: "onChange",
        resolver: yupResolver(createForumSchema)
    })
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "test", // unique name for your Field Array
    });
    const customStyles = {
        control: base => ({
            ...base,
            border: "0px !important",
            // This line disable the blue border
            boxShadow: 'none',
            borderBottom: 0,
        }),
        option: (provided, state) => ({
            ...provided,
            // padding: "10px",
            textAlign: "left",
            ":hover": {
                background: "#F6F7FB",
                color: "#122B46"
            },
            width: "100%",
            backgroundColor: state.isSelected ? "#1F4173" : "white",
            color: state.isSelected ? "#fff" : "#122B46",
            textTransform: "capitalize",
            fontSize: "13px"
        }),
        indicatorSeparator: (styles) => ({ display: 'none' }),
        control: (styles, state) => ({
            ...styles,
            backgroundColor: 'transparent',
            // padding: 5,
            border: countryError ? "1px solid red" : "1px solid #2E4C6D",
            ":hover": {
                border:
                    countryError ? "1px solid red" : "1px solid #2E4C6D",
            },
            width: "100%",
            boxShadow: "none",
            color: state.isSelected ? "#1F4173" : "#122B4680",
            borderRadius: '0.5rem'
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            borderRadius: '0.5rem',
            width: "100%",
            boxShadow: '10, 10, 10, black',
            justifyContent: 'start',
            borderBottom: 0,
            ':focus': {
                borderBottom: "0px !important"
            }
        }),
        placeholder: (provided, state) => ({
            ...provided,
            fontSize: 13,
            fontWeight: 400,
            color: '#122B4680',
        }),
        // selector view
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            return { ...provided, opacity, transition, color: '#122B46', fontSize: 13, fontWeight: 400, display: 'inline' };
        },
        menuList: (provided, state) => ({
            ...provided,
            overflow: "hidden",
            marginLeft: "7px",
            maxHeight: "200px",
            overflowY: 'scroll!important',
            fontSize: 16,
            paddingTop: "0px",
            paddingBottom: "0px",
            boxShadow: "none",
            borderRadius: "0px 0px 8px 8px",
            zIndex: 999
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "0px 0px 8px 8px",
            marginTop: "0px",
            boxShadow: "none",
            border: "1px solid #1f41731a",
            width: "calc(100% + 2px)",
            left: "-1px"
        }),
        input: (provided, state) => ({
            ...provided,
            fontSize: 16,
            borderBottom: "0px !important",
            color: "var(--primaryColor)",
            ':focus': {
                backgroundColor: "#8b0b316e",
                borderBottom: 0
            }
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: '#122B4680',
        })
    }

    useEffect(() => {
        if (data?.resources) {
            setResources()
            reset({
                test: [
                    ...data?.resources
                ]
            })
            const country_val = countries.filter(item => {
                if (item.label == `${data.country.charAt(0).toUpperCase() + data.country.slice(1)}`) {
                    return item

                }
            })
            setCountry(...country_val)
        } else {
            append(
                {
                    static_id: uid(),
                    link: "",
                    description: ""
                })
        }
    }, [data])

    const handelMoreLink = () => {
        append(
            {
                static_id: uid(),
                link: "",
                description: ""
            })
    }

    // Functions
    // Closes the modal
    const closeModal = () => {
        dispatch(toggleResourcesModal({
            status: false,
            data: {}
        }))
    }

    const handelvalue = (data) => {
        let array = fields.map((item, index) => {
            if (index == data?.index) {
                return {
                    ...item,
                    link: (data.link_value !== '' && data.link_value) ? data.link_value : item.link,
                    description: (data.description_value !== '' && data.description_value) ? data.description_value : item.description
                }
            } else {
                return item
            }
        })


        setResources([...array])

    }


    const handleRemoveLink_2 = (data) => {
        remove(data.index)
        deleteresources({
            res_id: data?.res_id,
            country_id: data?.country_id
        }, dispatch)
    }

    const handleRemoveLink = (data) => {
        return dispatch(deleteResourcesModal({
            status: true,
            data: {
                item_data: data,
                handleRemoveLink_2
            }
        }))

    }

    // Deletes the forum
    const addResources = async (submit_data) => {

        if (country == "" || !country) {
            return setCountryError(true)
        } else {
            setCountryError(false)
        }
        let obj_data;
        obj_data = {
            country: country.label,
            resources: submit_data?.test.filter(item => {
                if (item.link && item.link !== '' && item.description && item.description !== '') {
                    return item
                }
            }),
        }


        if (data?.type === "editRes") {
            obj_data = {
                ...obj_data,
                id: data.id
            }
        }

        const response = await addResourcesService(obj_data, dispatch)


    }

    return (
        <>
            <Modal show={status} size="lg" onHide={closeModal} className={`${delete_res_modal.status ? "d-none" : ""}`}>
                <Modal.Header>
                    <h6>{data?.type === "editFaq" ? "Edit Resources" : "Add Resources"}</h6>
                    <span onClick={closeModal} type="button">
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </span>
                </Modal.Header>
                <Modal.Body className="privacyBody privacyBody_5">
                    <div className='w-100 px-5'>
                        <Select
                            options={countries}
                            styles={customStyles}
                            {...register(`Country`)}
                            placeholder="Search Country..."
                            className={`reacr_select1
                            ${(errors?.Country && errors?.Country?.message) ? "form-control is-invalid mb-0" : ''}`}
                            onChange={(e) => {
                                setValue('Country', e?.label)
                                setCountryError(false)
                                setCountry(e)
                            }}
                            value={country}
                        // menuIsOpen
                        />
                        {countryError ? <div className="invalid-feedback is-invalid d-flex align-items-center justifl-content-start">
                            <strong>You need to select Country</strong>
                        </div> : ''}
                        {fields.length ? fields.map((item, index) => {
                            return <div className='res_link_div'>
                                <div className='mt-2 w-100 d-flex align-items-center justify-content-end'>
                                    <div className='remove_text_style' onClick={() => {
                                        handleRemoveLink({ index, country_id: item.country_id, res_id: item.res_id })
                                    }}>
                                        Remove Link
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    key={item.static_id} // important to include key with field's id
                                    {...register(`test.${index}.link`)}
                                    placeholder='Link...'
                                    className={`add_faq_input input_fq input_source  
                                     ${(errors?.test && errors?.test[index]?.link?.message) ? "form-control is-invalid mb-0 add_faq_input_eror" : ''}`}
                                    // defaultValue={data?.type === "editRes" ? item.link : ''}
                                    onChange={(e) => {

                                        handelvalue({
                                            index: index,
                                            link_value: e?.target?.value
                                        })
                                    }}
                                />
                                {(errors?.test && errors?.test[index]?.link?.message) ? <div className="invalid-feedback is-invalid d-flex align-items-center justifl-content-start">
                                    <strong>{errors?.test[index]?.link?.message.replace(`test[${index}].`, '')}</strong>
                                </div> : ''}
                                <textarea
                                    placeholder='Enter Description...'
                                    {...register(`test.${index}.description`)}
                                    className={`add_faq_input text_fq  text_res 
                                    ${(errors?.test && errors?.test[index]?.link?.message) ? "mt-0" : 'mt-2'}
                                    ${(errors?.test && errors?.test[index]?.description?.message) ? "form-control is-invalid mb-0 mt-0 add_faq_input_eror" : 'mt-2'}
                                    `}
                                    onChange={(e) => {
                                        handelvalue({
                                            index: index,
                                            description_value: e?.target?.value
                                        })
                                    }}
                                />
                                {(errors?.test && errors?.test[index]?.description?.message) ? <div className="invalid-feedback is-invalid d-flex align-items-center justifl-content-start">
                                    <strong>{errors?.test[index]?.description?.message.replace(`test[${index}].`, '')}</strong>
                                </div> : ''}
                                {(index < fields.length - 1) ? <hr className='res_hr' /> : ''}
                            </div>
                        }) : ''}

                        <div className='d-flex align-items-center justify-content-end add_link'>
                            <div
                                onClick={() => {
                                    handelMoreLink()
                                }}>Add more Link</div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer className="pt-0 justify-content-center">
                    <Button type='button' className="modalFootBtns btn" variant="primary" onClick={handleSubmit(addResources)}>
                        Add Resources
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
