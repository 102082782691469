import auth from "../user/behindScenes/Auth/AuthCheck";
import { avatars } from "./avatars/Avatars";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getKeyProfileLoc } from "./profileHelper";
import moment from "moment";
import Applogo from "../images/appLogo.svg"

const getLocalStorageKey = key => {
    let value = localStorage.getItem(key) ?? false;
    return value;
}

const setLocalStoragekey = (key, value) => {
    if (!key || value === undefined) return messageGenerator(false, "key or value is undefined")
    localStorage.setItem(key, value)
    return messageGenerator(false, "data is saved to localstorage", { key: value })
}

const messageGenerator = (status = false, message = '', data = {}) => {
    return { status, message, data }
}

const resHandler = res => {
    const { data } = res
    if (data?.status === true)
        return data
    throw new Error(res?.data?.message ?? "Something went wrong")
}

// Checks whether or not avatar image is used on profile currently
const isAvatarSelectedCurr = () => {
    let imgurl = "",
        check
    if (auth()) {
        imgurl = getKeyProfileLoc("image")
        if (imgurl && imgurl.indexOf("avatar") !== -1)
            avatars.forEach((curr, index) => {
                let src = curr.src
                src = src.split("/")
                imgurl = `${imgurl}`.split("/")
                src = src[src.length - 1]
                imgurl = imgurl[imgurl.length - 1]
                if (src === imgurl) {
                    check = messageGenerator(true, "Avatar is selected", {
                        currentSelected: curr.src,
                        avatarImageIndex: index
                    })
                    return false
                }
            })
    }

    if (!check || check === "") {
        check = messageGenerator(false, "Avatar is not selected")
    }

    return check
}

const areAtLastPage = (pageSize = 20, commentsCount = 0, currPage) => {
    var totalPages, isAtLastPage = false;
    pageSize = 20;
    totalPages = Math.ceil(commentsCount / pageSize);
    totalPages = totalPages === 0 ? (totalPages + 1) : totalPages;
    isAtLastPage = totalPages === currPage
    return isAtLastPage
}

// Returns profile visit link
const profileLinkToVisit = (obj) => {
    var isMyProfile = getKeyProfileLoc("user_id") === obj?.user_id
    if (!obj?.userslug) return "#"
    var linkToOtherProfile = `/userProfile/${obj?.userslug}`
    return `${(auth() && isMyProfile) ? "/profile" : linkToOtherProfile}`
}

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
}

// ** Converts table to CSV
function convertArrayOfObjectsToCSV(array) {
    let result
    const columnDelimiter = ','
    const lineDelimiter = '\n'
    const cols = Object.keys(array[0]).map(curr => curr?.toUpperCase())
    const keys = Object.keys(array[0])


    result = ''
    result += cols.join(columnDelimiter)
    result += lineDelimiter

    array.forEach(item => {
        let ctr = 0
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter
            result += item[key]
            ctr++
        })
        result += lineDelimiter
    })

    return result
}

const exportToCsv = ({ array = [] }) => {
    const link = document.createElement('a')
    const NewOne = array.map(item => {
        delete item.id
        delete item.source

        delete item.image
        return {
            ...item,
            status: item?.status === 1 ? "Active" : "InActive",
            post_as_anonymous: item?.post_as_anonymous === 1 ? "Yes" : "No",
            logintype: item.source === 2 ? "Gmail id" : item.source === 3 ? "Facebook id" : "Manual"
        }
    })
    dataToCSV(NewOne, link)

}

const exportToCsvDonation = ({ array = [] }) => {
    // console.log(array)
    const link = document.createElement('a')
    const NewOne = array.map(item => {
        delete item.id
        delete item.source

        delete item.image
        // console.log({ item })
        return {
            users: item.donated_by && item.display_name != null ? item.display_name : item.donated_by,
            amount_donated: item.amount,
            Donated_date: moment(item.created_at).format("MM-DD-YYYY"),
            Donated_type: item.donation_type === 1 ? "One Time" : item.donation_type === 2 ? "Monthly Subscription" : '',
        }
    })
    dataToCSV(NewOne, link)

}

const dataToCSV = (NewOne, link) => {
    let csv = convertArrayOfObjectsToCSV(NewOne)
    if (csv === null) return

    const filename = 'export.csv'

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`
    }

    link.setAttribute('href', encodeURI(csv))
    link.setAttribute('download', filename)
    link.click()
}

const convertArrayOfObjectsToPDF = (data, startDate, endDate) => {
    const doc = new jsPDF();
    // doc.scale(50, 50);
    // doc.addImage(Applogo, 'svg', 20, 40);

    // doc.text(20, 60, 'Data: ' + dataArray.join(', '));
    // Define the columns for your table
    const columns = ["Sr.","Users", "Amount Donated", "Donated Date", "Donated Type"];

    // Define an empty array to hold the rows of data
    const rows = [];

    // Loop through the data and push each object's values into the rows array
    let price = 0.00
    data.forEach((item, index) => {
        price = parseFloat(price) + parseFloat(item.amount)
        const row = [ index + 1
            ,item.display_name == null || !item.display_name ? item.donated_by : item.display_name
            , `$${item.amount}`
            , moment(item.created_at).format('MM-DD-YYYY')
            , item.donation_type === 2 ? "Monthly Subscription" : "One time"
        ];
        rows.push(row);
    });

    // Add the table to the PDF document

    doc.setFontSize(11);

    doc.setFont("helvetica");

    // Set text color
    doc.setTextColor("#2E4C6D"); // Red color

    doc.text(`${'Generated Date: ' + moment(new Date()).format('MM-DD-YYYY')}`, 140, 16);

    if (startDate && endDate && startDate != '' && endDate != "") {
        doc.text(`${'Time Period: ' + moment(startDate).format('MM-DD-YYYY') + " To " + moment(endDate).format('MM-DD-YYYY')}`, 15, 10);
    }else {
        doc.text(`${'Time Period: '}`, 15, 10);
    }

    doc.text(`${'Generated By: ' + "admin@admin.com"}`, 140, 10);

    doc.text(`${'Total Donation: $' + price}`, 15, 16);

    let table = doc.autoTable({
        head: [columns],
        body: rows,
        startY: 22
    });


    doc.save("donation_table.pdf");
}

const exportToPdf = ({ array = [], startDate, endDate }) => {
    const link = document.createElement('a')
    const NewOne = array.map(item => {
        delete item.id
        delete item.source

        delete item.image
        return {
            ...item,
            status: item?.status === 1 ? "Active" : "InActive",
            post_as_anonymous: item?.post_as_anonymous === 1 ? "Yes" : "No",
            logintype: item.source === 2 ? "Gmail id" : item.source === 3 ? "Facebook id" : "Manual"
        }
    })


    let csv = convertArrayOfObjectsToPDF(NewOne, startDate, endDate)

    return
    if (csv === null) return

    const filename = 'export.csv'

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`
    }

    link.setAttribute('href', encodeURI(csv))
    link.setAttribute('download', filename)
    link.click()
}


const scrollDetails = {
    setScrollDetails({ scrollPosition, pageName }) {
        localStorage.setItem("scrollDetails", JSON.stringify({ scrollPosition: scrollPosition ?? 0, pageName: pageName ?? "" }))
    },
    getScrollDetails() {
        const scrollDetails = localStorage.getItem("scrollDetails") ?? "{}";
        return JSON.parse(scrollDetails)
    }
}

const isAdminLoggedIn = () => {
    const userData = getKeyProfileLoc("token", true, true)
    return userData ? true : false
}

const uid = function(){
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export {
    getLocalStorageKey,
    isAvatarSelectedCurr,
    setLocalStoragekey,
    resHandler,
    areAtLastPage,
    scrollToTop,
    exportToCsv,
    exportToPdf,
    scrollDetails,
    isAdminLoggedIn,
    profileLinkToVisit,
    exportToCsvDonation,
    uid,
}